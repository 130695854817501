<script setup lang="ts">
import InfoIconTooltip from '~/components/common/InfoIconTooltip.vue';

type Props = {
  loading?: boolean;
  amount?: number;
  currency?: string;
  title?: string;
  colorBySign?: boolean;
  addPlusSign?: boolean;
  link?: string;
  help?: string;
  theme?: 'light' | 'dark';
};

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  amount: undefined,
  currency: undefined,
  title: undefined,
  colorBySign: false,
  addPlusSign: false,
  link: undefined,
  help: undefined,
  theme: 'dark',
});

const valueClassNames = computed(() => ({
  ...(props.colorBySign && {
    'money-amount-value--positive': props.amount! >= 0,
    'money-amount-value--negative': props.amount! < 0,
  }),
}));

const formatKey = computed(() =>
  props.addPlusSign
    ? `currencyTwoDecimalsWithSign-${props.currency}`
    : `currencyTwoDecimals-${props.currency}`,
);
</script>

<template>
  <div class="money-amount">
    <div v-if="title" class="money-amount__title">
      <span>{{ title }}</span>

      <InfoIconTooltip v-if="help" :theme="theme">{{ help }}</InfoIconTooltip>
    </div>

    <div v-if="loading" class="money-amount-value money-amount-value__money">
      {{ $t('global.loading') }}
    </div>

    <i18n-n
      v-else
      tag="div"
      class="money-amount-value"
      scope="global"
      :class="valueClassNames"
      :value="amount!"
      :format="formatKey"
    >
      <template #currency="slotProps">
        <span class="money-amount-value__currency">{{ slotProps.currency }}</span>
      </template>

      <template #minusSign="slotProps">
        <span class="money-amount-value__money">{{ slotProps.minusSign }}</span>
      </template>

      <template #plusSign="slotProps">
        <span class="money-amount-value__money">{{ slotProps.plusSign }}</span>
      </template>

      <template #integer="slotProps">
        <span class="money-amount-value__money">{{ slotProps.integer }}</span>
      </template>

      <template #group>
        <span class="money-amount-value__money">&nbsp;</span>
      </template>

      <template #decimal="slotProps">
        <span class="money-amount-value__decimals">{{ slotProps.decimal }}</span>
      </template>

      <template #fraction="slotProps">
        <span class="money-amount-value__decimals">{{ slotProps.fraction }}</span>
      </template>

      <template #literal>
        <span>&nbsp;</span>
      </template>
    </i18n-n>
  </div>
</template>

<style lang="scss" scoped>
.money-amount {
  display: flex;
  flex-direction: column;
}

.money-amount__title {
  display: inline-flex;
}

.money-amount-value {
  font-size: var(--font-size-md);

  &__money {
    font-weight: 700;
    font-size: var(--font-size-xxxl);
  }

  &__decimals {
    font-weight: 700;
  }

  &--positive {
    color: var(--green-basic);
  }

  &--negative {
    color: var(--red-basic);
  }
}
</style>
